@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
html, body {
  overscroll-behavior-y: none !important;
}

.header-horizontal {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  height: 68px;
  position: fixed;
  max-height: 68px;
  padding: 20px 30px;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-horizontal.is-sticky {
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  width: 100%;
  height: 68px;
  position: fixed;
  max-height: 68px;
  background-color: #8f3769;
  background: linear-gradient(to bottom, #8f3769 0%,#61245f 100%);
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.5);
}

/* Header Marquee Data */
.header-data {
  top: 0;
  bottom: 0;
  left: 140px;
  height: 68px;
  max-height: 68px;
  position: absolute;
  display: flex;
  padding: 20px 15px;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(100% - 475px);
  max-width: calc(100% - 475px);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
}
.header-horizontal .header-data {
  display: none !important;
}
.header-horizontal.is-sticky .header-data {
  display: flex !important;
}
.marquee {
  width: 100%;
  height: 28px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.marquee-inner {
  margin: 0;
  width: 200%;
  height: 28px;
  display: block;
  position: absolute;
  -webkit-animation: marquee 20s linear infinite;
  animation: marquee 20s linear infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.marquee-inner:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.header-data-scroll {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-data-scroll > li {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  transition: all .2s ease-out;
}
.header-data-scroll > li:first-child {
  padding-left: 0;
}
.header-data-scroll > li strong {
  padding-left: 5px;
}
.header-data-scroll:hover > li {
  opacity: .5;
}
.header-data-scroll:hover > li:hover {
  opacity: 1;
  cursor: pointer;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

/* Hero Footer Data */
.hero-footer {
  padding: 0;
  z-index: 31;
  width: 100%;
  bottom: 20px;
  position: absolute;
}
@media (max-width: 767px) {
  .header-horizontal {
    padding: 20px;
  }
  .header-data {
    left: 120px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    min-width: calc(100% - 190px);
    max-width: calc(100% - 190px);
  }
  .marquee-inner {
    width: auto;
    -webkit-animation: marquee 10s linear infinite;
    animation: marquee 10s linear infinite;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .header-data-scroll > li {
    padding-left: 7.5px;
    padding-right: 7.5px;
    font-size: 13px !important;
  }
  .header-data-scroll > li:first-child {
    padding-left: 7.5px;
  }
}

